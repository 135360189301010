import React, { useState } from "react";
import { ColumnValueRendererProps } from "../table.data.interface";
import moment from "moment";
import { Tooltip } from "monday-ui-react-core";

const TimelineColumn = ({ columnData }: ColumnValueRendererProps) => {
  const value = columnData.value as string; // Ensure value is treated as a string
  const [isHovered, setIsHovered] = useState(false);

  if (columnData.value === "Empty Column") {
    return null;
  }

  const [startDateStr, endDateStr] = value.split(" - "); // Split the range into start and end

  // Parse the dates
  const startDate = moment(startDateStr);
  const endDate = moment(endDateStr);
  const currentYear = moment().year(); //

  // Format based on conditions
  let formattedDateRange: string;
  if (startDate.year() === endDate.year() && startDate.year() === currentYear) {
    // Same month and current year
    if (startDate.month() === endDate.month()) {
      formattedDateRange = `${startDate.format("MMM D")} - ${endDate.date()}`;
    } else {
      formattedDateRange = `${startDate.format("MMM D")} - ${endDate.format(
        "MMM D"
      )}`;
    }
  } else if (startDate.year() === endDate.year()) {
    // Same year but not the current year
    formattedDateRange = `${startDate.format("MMM D, 'YY")} - ${endDate.format(
      "MMM D, 'YY"
    )}`;
  } else {
    // Different years
    formattedDateRange = `${startDate.format("MMM D, 'YY")} - ${endDate.format(
      "MMM D, 'YY"
    )}`;
  }

  // Calculate days apart
  const daysApart = endDate.diff(startDate, "days") + 1;

  return (
    <>
      <Tooltip content={formattedDateRange}>
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ position: "relative", cursor: "pointer" }}
          className="w-[6.688rem] flex items-center justify-center px-2 rounded-full whitespace-nowrap text-ellipsis overflow-hidden bg-[#579bfc] text-white"
        >
          <span className="overflow-hidden text-ellipsis whitespace-nowrap">
            {isHovered ? `${daysApart}d` : formattedDateRange}
          </span>
        </div>
      </Tooltip>
    </>
  );
};

export default TimelineColumn;

import React, { useEffect, useState } from "react";
import ButtonComponent from "../Button";
import mondaySdk from "monday-sdk-js";
import { getItemNames } from "../../services/monday-service";
import PageLoader from "../PageLoader";
import useDeleteItems from "../../hooks/api/useDeleteItems";

const monday = mondaySdk();

const RemoveModal = () => {
  const { deleteHook, unlinkHook } = useDeleteItems();
  const [userId, setUserId] = useState<string>();
  const [itemId, setItemId] = useState<string>();
  const [boardId, setBoardId] = useState<string>();
  const [boardName, setBoardName] = useState<string>();
  const [itemName, setItemName] = useState();
  const [loading, setLoading] = useState(false);

  const getUrlParams = (url: string): Record<string, string> => {
    const searchParams = new URLSearchParams(new URL(url).search);
    const params: Record<string, string> = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    return params;
  };

  useEffect(() => {
    const params = getUrlParams(window.location.href);

    setUserId(params["userId"]);
    setItemId(params["targetItemId"]);
    setBoardId(params["targetBoardId"]);
    setBoardName(params["boardName"]);
  }, []);

  useEffect(() => {
    if (itemId) {
      const fetchItemName = async () => {
        const response = await getItemNames(itemId);

        setItemName(response[0]?.name);
      };

      fetchItemName();
    }
  }, [itemId]);

  const handleCloseModal = () => {
    monday.execute("closeAppFeatureModal").then((res) => {});
  };

  const handleRemove = async () => {
    setLoading(true);
    monday.execute("notice", {
      message: `Deleting item in ${boardName}.`,
      type: "info", // or "error" (red), or "info" (blue)
      timeout: 10000,
    });
    await deleteHook(userId, itemId, boardId);
    setLoading(false);
    monday.execute("closeAppFeatureModal").then((res) => {});
  };

  const handleUnlinkItem = async () => {
    setLoading(true);
    monday.execute("notice", {
      message: `Unlinking item in ${boardName}.`,
      type: "info", // or "error" (red), or "info" (blue)
      timeout: 10000,
    });
    await unlinkHook(userId, itemId, boardId);
    setLoading(false);
    monday.execute("closeAppFeatureModal").then((res) => {});
  };

  if (!itemName || loading) {
    return <PageLoader />;
  }

  return (
    <div className="h-full px-8 pt-6 pb-4 overflow-hidden delete-custom-modal">
      <div className="flex items-center">
        <h1 className="m-0 font-semibold">Delete or unlink item?</h1>
      </div>
      <div>
        <p className="m-0 text-sm text-[#979797]">Same Item Multiple Boards</p>
      </div>
      <div className="mt-4">
        <p className="m-0">
          Are you sure you want to delete or unlink{" "}
          <span className="font-semibold">{itemName}</span> from{" "}
          <span className="font-semibold">{boardName}</span>?
        </p>
      </div>
      <div className="flex justify-end gap-2 mt-6">
        <ButtonComponent
          onClick={handleCloseModal}
          kind="tertiary"
          text="Cancel"
        />
        <ButtonComponent
          onClick={handleUnlinkItem}
          kind="secondary"
          color="negative"
          text="Unlink"
        />
        <ButtonComponent
          onClick={handleRemove}
          kind="primary"
          color="negative"
          text="Delete"
        />
      </div>
    </div>
  );
};

export default RemoveModal;

import { useEffect, useRef, useState } from "react";
import WorkspaceList from "./WorkspaceList";
import useWorkspaceList from "../../hooks/componentHooks/useWorkspaceList";
import { WorkspaceType } from "../../types/workspace.list.interface";
import ButtonComponent from "../Button";
import { Warning } from "monday-ui-react-core/icons";
import Spinner from "../Spinner";
import useClickOutside from "../../hooks/componentHooks/useClickOutside";
import useCalcHeight from "../../hooks/componentHooks/useCalcHeight";
import AddItem from "./additem";

interface AddItemDropdownInterfaceProps {
  centered: boolean;
  setOpen?: (value: boolean) => void;
  fullWidth: boolean;
  bigAccount: boolean;
  batchSize?: number;
  disableAll?: boolean;
  moreThan20?: boolean;
}

const AddItemDropdown = ({
  centered,
  setOpen,
  fullWidth,
  bigAccount,
  batchSize = 50,
  disableAll = false,
  moreThan20,
}: AddItemDropdownInterfaceProps): JSX.Element => {
  const [retry, setRetry] = useState(false);
  const [reauth, setReauth] = useState(0);
  const dropdownRef = useRef<any>(null);
  const { workspaceListdata, isLoading, error, mutate } =
    useWorkspaceList(bigAccount);

  const { height, setHeight, calcHeight } = useCalcHeight();

  useEffect(() => {
    if (dropdownRef?.current) {
      setHeight(
        dropdownRef?.current?.firstChild.offsetHeight > 400
          ? 400
          : dropdownRef?.current?.firstChild.offsetHeight
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeDropdown = () => {
    setOpen?.(false);
  };

  useClickOutside(dropdownRef, closeDropdown);

  const retryFetch = async () => {
    try {
      setRetry(true);
      setReauth(reauth + 1);
      await mutate();
    } catch (error) {
      console.error(error);
    } finally {
      setRetry(false);
    }
  };

  if ((isLoading || retry) && bigAccount) {
    return (
      <div
        className={`z-[1000] add-item-dropdown top-[58px] ${
          centered ? "left-1/2 transform -translate-x-1/2" : "right-0"
        } ${
          fullWidth ? "w-full h-screen" : "w-[300px] absolute"
        }  rounded bg-white px-2 pb-1 overflow-y-scroll overflow-x-hidden shadow-2xl`}
      >
        <div className="flex items-center gap-2 pt-4 pb-4">
          <Spinner /> Getting your workspaces
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div
        className={`z-[1000] add-item-dropdown top-[58px] ${
          centered ? "left-1/2 transform -translate-x-1/2" : "right-0"
        } ${
          fullWidth ? "w-full h-screen" : "w-[300px] absolute"
        }  rounded bg-white px-2 pb-1 overflow-y-scroll overflow-x-hidden shadow-2xl`}
      >
        <div className="flex flex-col items-center justify-center gap-2 p-2">
          {reauth >= 1 ? (
            <div className="flex items-center text-center justify-center max-w-[241px]">
              <span>
                If the error persists, try authorizing again using this{" "}
                <a
                  className="hover:underline"
                  rel="noreferrer"
                  href="https://auth.monday.com/oauth2/authorize?client_id=9d9d91f40e511e3954b7d1df7184115e"
                  target="_blank"
                >
                  link
                </a>
              </span>
            </div>
          ) : (
            <>
              <div className="flex items-center justify-center">
                <Warning className="mr-1" />
                <span>An error occurred. Please try again.</span>
              </div>
            </>
          )}

          <div className="flex justify-center">
            <ButtonComponent
              text="Retry"
              className="retry-button"
              onClick={retryFetch}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        height: `${fullWidth ? "100vh" : `${height}px`}`,
      }}
      ref={dropdownRef}
      className={`z-[1000] add-item-dropdown top-[58px] ${
        centered ? "left-1/2 transform -translate-x-1/2" : "right-0"
      } ${
        fullWidth ? "w-full" : "w-[300px] absolute"
      }  rounded bg-white px-2 pb-1 overflow-y-scroll overflow-x-hidden shadow-2xl`}
    >
      {/* Workspace List */}
      {bigAccount ? (
        <WorkspaceList
          calcHeight={calcHeight}
          data={workspaceListdata as WorkspaceType[]}
          batchSize={batchSize}
          disableAll={disableAll}
          moreThan20={moreThan20}
        />
      ) : (
        // Small accounts
        <AddItem
          calcHeight={calcHeight}
          batchSize={batchSize}
          disableAll={disableAll}
          moreThan20={moreThan20}
        />
      )}
    </div>
  );
};

export default AddItemDropdown;

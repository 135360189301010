import React from "react";
import { ColumnValueRendererProps } from "../table.data.interface";
import { Chips } from "monday-ui-react-core";

const DropdownColumn = ({ columnData }: ColumnValueRendererProps) => {
  if (columnData.value === "Empty Column") {
    return null;
  }

  const dropdownValues =
    typeof columnData.value === "string" && columnData.value !== "Empty Column"
      ? columnData.value.split(", ")
      : [];

  return (
    <div>
      {dropdownValues.map((value, index) => (
        <Chips
          readOnly={true}
          className="first:!ml-0"
          key={index + 1}
          label={value}
        />
      ))}
    </div>
  );
};

export default DropdownColumn;

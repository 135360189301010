import React, { useEffect, useState } from "react";
import { ColumnValueRendererProps } from "../table.data.interface";
import { getItemNames } from "../../../services/monday-service";

const ConnectBoardsColumn = ({ columnData }: ColumnValueRendererProps) => {
  const [itemNames, setItemNames] = useState();
  useEffect(() => {
    if (columnData.value !== "Empty Column") {
      const fetchItemName = async () => {
        const response = await getItemNames(
          (columnData?.value as string).split(", ")
        );

        setItemNames(response.map((item: any) => item.name).join(", "));
      };

      fetchItemName();
    }
  }, [columnData.value]);

  if (columnData.value === "Empty Column") {
    return null;
  }

  return <div>{itemNames}</div>;
};

export default ConnectBoardsColumn;

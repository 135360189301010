import { Progress } from "antd";
import React from "react";
import useUsageData from "../../hooks/api/useUsageData";
import { Skeleton } from "monday-ui-react-core";
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();

const UsageSection = () => {
  const { isLoading, error, usageData } = useUsageData();

  if (isLoading) {
    return (
      <div className="max-w-[640px] mx-auto">
        <Skeleton fullWidth height={58} />
      </div>
    );
  }

  if (error || !usageData) {
    return (
      <div className="max-w-[640px] mx-auto bg-[#F5F6F8] w-full min-h-[58px] rounded-lg flex items-center justify-center">
        <p className="text-light-secondary-text-color">
          Error loading usage data. Please try again later.
        </p>
      </div>
    );
  }

  const openPlanSelection = () => {
    monday.execute("openPlanSelection", {
      isInPlanSelection: false,
    });
  };

  return (
    <div className="max-w-[640px] mx-auto">
      <div className="grid grid-cols-[1fr_1fr_1fr] bg-[#F5F6F8] min-h-[3.375rem] rounded-lg">
        {/* Shared Items */}
        <div className="flex items-center gap-3 pl-[0.563rem] pr-[1.063rem] py-[0.563rem]">
          <div>
            <Progress
              type="circle"
              percent={usageData.usagePercentItems}
              size={36}
              trailColor="#DCDFEC"
              showInfo={false}
              strokeColor={
                usageData.usagePercentItems >= 100 ? "#D83A52" : "#0273EA"
              }
              status={
                usageData.usagePercentItems >= 100 ? "exception" : "normal"
              }
              strokeWidth={10}
            />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col justify-center">
              <span className="!font-figtree text-base text-light-secondary-text-color font-medium leading-5">
                {usageData.itemUsage}/{usageData.maxItems}
              </span>
              <span className="!font-figtree text-sm text-light-secondary-text-color font-medium leading-5">
                Shared Items
              </span>
            </div>
          </div>
        </div>

        {/* Shared Boards */}
        <div className="flex items-center gap-3 py-[0.563rem] pl-[0.625rem] pr-[1.063rem] border border-solid border-l-[#E7E9EF] border-r-[#E7E9EF] border-b-0 border-t-0">
          <div>
            <Progress
              type="circle"
              percent={usageData.usagePercentBoards}
              size={36}
              trailColor="#DCDFEC"
              showInfo={false}
              strokeColor={
                usageData.usagePercentBoards >= 100 ? "#D83A52" : "#FDAB3D"
              }
              status={
                usageData.usagePercentBoards >= 100 ? "exception" : "normal"
              }
              strokeWidth={10}
            />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col justify-center">
              <span className="!font-figtree text-base text-light-secondary-text-color font-medium leading-5">
                {usageData.boardUsage}/{usageData.maxBoards}
              </span>
              <span className="!font-figtree text-sm text-light-secondary-text-color font-medium leading-5">
                Shared Boards
              </span>
            </div>
          </div>
        </div>

        {/* Upgrade Plan */}
        <div className="pr-[0.563rem] py-[0.563rem] flex justify-center items-center flex-col leading-0">
          <span className="!font-figtree text-base font-medium text-light-secondary-text-color">
            {usageData.planName}
          </span>
          <span
            onClick={() => openPlanSelection()}
            className="text-xs !font-figtree leading-0 font-medium text-primary-color cursor-pointer"
          >
            Upgrade Plan
          </span>
        </div>
      </div>
    </div>
  );
};

export default UsageSection;

export const getItemNamesQl = (itemIds: number[]) => `
  query {
    items (limit: 1000, ids: ${JSON.stringify(
      itemIds
    )}, exclude_nonactive: true) {
      name
      id
      state
      board {
        state
        id
      }
    }
  }
`;

export const getWorkspacesQl = () => `query {
all_workspaces:workspaces (limit: 1000) {
    id
    name
  }
 main_workspace:boards (workspace_ids: [null] limit:1) {
    name
    workspace{
      id
      name
    }
  }
}`;

export const getAccountSizeQl = () => `query {
    boards(limit: 500){
      id
    }
}`;

export const checkSubitemsQl = (itemIdArray: Number[]) => `query {
        items (ids: [${itemIdArray}], limit: 100) {
          subitems{
            id
          }
        }
      }`;

export const checkUpdatesQl = (itemIdArray: Number[]) => `query {
        items (ids: [${itemIdArray}]) {
          updates{
            id
          }
        }
      }`;

export const getStatusColumnDataQl = (
  itemIdArray: Number[],
  columnId: string
) => `query {
  items (ids: ${JSON.stringify(itemIdArray)}) {
    column_values (ids: [${JSON.stringify(columnId)}]) {
      ... on StatusValue {
        index
        value
        text
        label_style {
        color
        }
      }
    }
  }
}`;

export const getPersonColumnDataQl = (
  itemIdArray: number[],
  columnId: string
) => `query {
  items (ids: ${JSON.stringify(itemIdArray)}) {
    column_values (ids: [${JSON.stringify(columnId)}]) {
      ... on PeopleValue {
        persons_and_teams{
          id
          kind
        }
      }
      }
    }
  }
}`;

export const getFileColumnDataQl = (itemIdArray: number[], columnId: string) =>
  `query {
  items (ids: ${JSON.stringify(itemIdArray)}) {
    assets(column_ids: [${JSON.stringify(columnId)}]){
      name
      file_extension
      url
      url_thumbnail
      id
    }
    }
}`;

import simbApi from ".";
import { REINSTALL_WEBHOOKS } from "../../data/constants/endpoints";

export const reinstallWebhooks = async (
  access_token: string,
  userId: string,
  boardId: string
) => {
  try {
    const response = await simbApi.post(
      REINSTALL_WEBHOOKS,
      { userId: userId, boardId: boardId },
      {
        headers: { Authorization: `Bearer ${access_token}` },
        timeout: 60000,
      }
    );

    if (response?.data?.error) {
      throw new Error(response?.data?.error);
    }

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

import React, { useState, useEffect } from "react";
import mondaySdk from "monday-sdk-js";
import { Switch } from "antd";
import { Info } from "monday-ui-react-core/icons";
import { Tooltip } from "monday-ui-react-core";

const monday = mondaySdk();

const SharedSubitemSettings = ({
  isLoading,
  error,
  subitemSettings,
  updateSettings,
  userId,
  itemIds,
}) => {
  const [sharedSettings, setSharedSettings] = useState(true);

  useEffect(() => {
    setSharedSettings(subitemSettings);
  }, [subitemSettings]);

  const handleClick = async (checked) => {
    try {
      setSharedSettings(checked);
      await updateSettings(itemIds, checked);

      monday.execute("notice", {
        message: checked
          ? "New subitems on this subItem will now appear on all other locations"
          : "New subitems WILL NOT appear on other subItem locations",
        type: "info", // or "error" (red), or "info" (blue)
        timeout: 5000,
      });
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  if (error) {
    console.error(error);
  }

  return (
    <div className="flex flex-row items-center justify-between gap-2">
      <div className="flex flex-row items-center gap-1">
        <p className="mr-0.5 ms-2 text-sm">Shared subitems</p>
        <Tooltip
          zIndex={1210}
          content="When enabled, newly created subitems on this item will sync with the sync item."
        >
          <Info />
        </Tooltip>
      </div>
      <Switch
        onChange={(checked) => {
          handleClick(checked);
        }}
        checked={sharedSettings}
        loading={isLoading}
      />
    </div>
  );
};

export default SharedSubitemSettings;

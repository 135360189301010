import React from "react";
import { ColumnValueRendererProps } from "../table.data.interface";

const DateColumn = ({ columnData }: ColumnValueRendererProps) => {
  if (columnData.value === "Empty Column") {
    return null;
  }
  const moment = require("moment"); // Ensure you import Moment.js if using Node.js

  const formattedDate = moment(columnData.value).format("MMM D, YYYY");

  return <div className="font-semibold">{formattedDate}</div>;
};

export default DateColumn;

import React from "react";
import { ColumnValueRendererProps } from "../table.data.interface";
import Star from "@mui/icons-material/Star";

const RatingColumn = ({ columnData }: ColumnValueRendererProps) => {
  if (columnData.value === "Empty Column") {
    return null;
  }
  const ratingValue = columnData.value; // Assuming this is a number from 1 to 5

  return (
    <div className="flex items-center gap-1">
      {Array.from({ length: 5 }, (_, index) => {
        const isActive = index + 1 <= (ratingValue as number); // Determine if this star is active
        const color = isActive ? "#fdab3d" : "#c4c4c4"; // Blue if active, gray if not

        return (
          <div
            key={index}
            className={`flex gap-1 items-center justify-center  p-1 rounded-full`}
            // Add padding and rounded corners for effect
            style={{ width: "16px", height: "16px", color: `${color}` }} // Set fixed size for the stars
          >
            <Star color="inherit" />
            {/* Change color based on active state */}
          </div>
        );
      })}
    </div>
  );
};

export default RatingColumn;

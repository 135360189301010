import React from "react";
import { ColumnValueRendererProps } from "../table.data.interface";

const EmailColumn = ({ columnData }: ColumnValueRendererProps) => {
  if (columnData.value === "Empty Column") {
    return null;
  }

  return (
    <div>
      <a
        href={`mailto:${columnData.value}`}
        className="no-underline cursor-pointer hover:underline"
      >
        {columnData.value}
      </a>
    </div>
  );
};

export default EmailColumn;

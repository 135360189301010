import useSWR from "swr";
import useUser from "../context/userHook";
import useBoardAndItems from "../context/useBoardAndItems";
import { getItemLocations } from "../../utils/requests/itemRequests";
import { useNavigate } from "react-router-dom";
import useTokenHook from "../context/useTokenHook";
import { TableDataInterface } from "../../components/ItemViewSection/table.data.interface";

const useItemLocations = () => {
  const { userId } = useUser();
  const { itemIds, currentBoard } = useBoardAndItems();
  const { token } = useTokenHook();

  const navigate = useNavigate();

  const { isLoading, error, data, mutate, isValidating } =
    useSWR<TableDataInterface | null>(
      token && userId ? `itemLocations:${userId}` : null,
      () => getItemLocations(token, itemIds, currentBoard, userId),
      {
        onSuccess: (data) => {
          // Transform the data to match the required format
          if (data && data.linkedBoards && data.columns) {
            const linkedBoardMap = new Map(
              data.linkedBoards.map((board) => [board.boardId, board.boardName])
            );

            data.columns.forEach((column) => {
              // Ensure boards are transformed correctly
              const initialBoards = column.boards as string[]; // Cast to string array
              column.boards = initialBoards
                .filter((boardId) => linkedBoardMap.has(boardId))
                .map((boardId) => ({
                  boardId: boardId, // Ensure this is a string
                  boardName: linkedBoardMap.get(boardId) || "", // Fallback to empty string
                }));
            });
          }
        },
        onError: (error) => {
          if (
            error?.response?.status === 401 &&
            error?.response?.data?.message === "User not found"
          ) {
            navigate("/onboarding");
          }
        },
        onErrorRetry: (error) => {
          if (
            error?.response?.status === 401 &&
            error?.response?.data?.message === "User not found"
          ) {
            navigate("/onboarding");
          }
        },
      }
    );

  return {
    isLoading,
    error: error,
    tableData: data || null,
    itemIds: itemIds || [],
    mutateItemLocations: mutate,
    isValidating,
  };
};

export default useItemLocations;

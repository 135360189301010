import useSWR from "swr";
import useUser from "../context/userHook";

import useTokenHook from "../context/useTokenHook";
import { fetchUsage } from "../../utils/requests/usageRequest";

const useUsageData = () => {
  const { userId } = useUser();

  const { token } = useTokenHook();

  const { isLoading, error, data, mutate, isValidating } = useSWR(
    token && userId ? `usageData:${userId}` : null,
    () => fetchUsage(token)
  );

  return {
    isLoading,
    error,
    usageData: data || null,
    mutateUsage: mutate,
    isValidating,
  };
};

export default useUsageData;

import { useState } from "react";
import { IconButton, Tooltip } from "monday-ui-react-core";
import { IoSync } from "react-icons/io5";
import useBoardAndItems from "../../hooks/context/useBoardAndItems";
import useUser from "../../hooks/context/userHook";
import { reinstallWebhooks } from "../../utils/requests/reinstallWebhooks";
import useTokenHook from "../../hooks/context/useTokenHook";
import mondaySdk from "monday-sdk-js";
import { Info } from "monday-ui-react-core/icons";

const monday = mondaySdk();

const ReinstallWebhooks = () => {
  const { currentBoard } = useBoardAndItems();
  const { userId } = useUser();
  const { token } = useTokenHook();
  const [loading, setLoading] = useState(false);

  const handleReinstallWebhooks = async () => {
    try {
      setLoading(true);

      const response = await reinstallWebhooks(token, userId, currentBoard);
      if (response?.data?.statusCode === 200) {
        monday.execute("notice", {
          message: "Webhooks reinstalled",
          type: "success", // or "error" (red), or "info" (blue)
          timeout: 10000,
        });
      }
    } catch (error) {
      console.error(error);
      monday.execute("notice", {
        message: "Webhooks failed to reinstall",
        type: "error", // or "error" (red), or "info" (blue)
        timeout: 10000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-row gap-1">
        <p className="text-sm">Reinstall Webhooks</p>
        <Tooltip
          zIndex={1210}
          content="Reinstall webhooks on the current board"
        >
          <Info />
        </Tooltip>
      </div>
      <IconButton
        onClick={handleReinstallWebhooks}
        icon={IoSync}
        size="small"
        tooltipContent="Reinstall webhooks on the Current Board"
        kind={IconButton.kinds.SECONDARY}
        loading={loading}
      />
    </div>
  );
};

export default ReinstallWebhooks;

import React, { useState } from "react";
import { ColumnValueRendererProps } from "../table.data.interface";
import { DropdownChevronDown } from "monday-ui-react-core/icons";

const LongTextColumn = ({ columnData }: ColumnValueRendererProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [expand, setExpand] = useState(false);

  if (columnData.value === "Empty Column") {
    return null;
  }

  const handleExpand = () => {
    try {
      setExpand(!expand);
    } catch (error) {}
  };
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`flex items-center justify-center p-2 transition overflow-hidden cursor-pointer rounded ${
        isHovered ? "bg-[#f5f6f8]" : ""
      } ${
        expand
          ? "bg-[#f5f6f8] max-h-[400px]"
          : "max-h-[36px] whitespace-nowrap text-ellipsis"
      }`}
    >
      <div
        className={`${
          expand
            ? "text-wrap"
            : "overflow-hidden cursor-pointer whitespace-nowrap text-ellipsis"
        }`}
      >
        <span>{columnData.value}</span>
      </div>
      <span className="flex items-center self-start justify-center">
        <DropdownChevronDown
          onClick={() => {
            handleExpand();
          }}
          color=""
          className={`transition duration-300 ${
            isHovered ? "opacity-100" : "opacity-0"
          } ${expand ? "-rotate-180 opacity-100" : "rotate-0"}`}
        />
      </span>
    </div>
  );
};

export default LongTextColumn;

// iconHelpers.tsx
import React from "react";
import {
  Board,
  Person,
  Status,
  Counter,
  Calendar,
  Email,
  Mobile,
  Dropdown,
  TextCopy,
  Favorite,
  Link,
  LongText,
  Timeline,
  File,
  Checkbox,
  Connect,
  Location,
  Tags,
} from "monday-ui-react-core/icons"; // Add more icons as needed

// Helper function to select icon based on column type
export const getColumnIcon = (type: string) => {
  switch (type) {
    case "person":
      return <Person size={16} color="#676879" />;
    case "status":
      return <Status size={16} color="#676879" />;
    case "numbers":
      return <Counter size={16} color="#676879" />;
    case "date":
      return <Calendar size={16} color="#676879" />;
    case "email":
      return <Email size={16} color="#676879" />;
    case "phone":
      return <Mobile size={16} color="#676879" />;
    case "dropdown":
      return <Dropdown size={16} color="#676879" />;
    case "text":
      return <TextCopy size={16} color="#676879" />;
    case "rating":
      return <Favorite size={16} color="#676879" />;
    case "link":
      return <Link size={16} color="#676879" />;
    case "long_text":
      return <LongText size={16} color="#676879" />;
    case "timeline":
      return <Timeline size={16} color="#676879" />;
    case "file":
      return <File size={16} color="#676879" />;
    case "checkbox":
      return <Checkbox size={16} color="#676879" />;
    case "board_relation":
      return <Connect size={16} color="#676879" />;
    case "location":
      return <Location size={16} color="#676879" />;
    case "tags":
      return <Tags size={16} color="#676879" />;
    default:
      return <Board size={16} color="#676879" />;
  }
};

import React, { useEffect, useState } from "react";
import { ColumnValueRendererProps } from "../table.data.interface";
import { MondayApiQueryInstance } from "../../../services/monday/monday-service-ts";

interface LabelStyle {
  color: string;
}

interface StatusColumnData {
  label_style: LabelStyle;
  index: number;
  value: string;
  text: string;
  // Add other properties as needed based on the API response
}

const StatusColumn = ({ columnData, itemIds }: ColumnValueRendererProps) => {
  const [statusColumnData, setStatusColumnData] =
    useState<StatusColumnData | null>(null);

  useEffect(() => {
    if (itemIds && itemIds.length > 0) {
      const fetchStatusColumnData = async (itemIds: number[]) => {
        try {
          const response = await MondayApiQueryInstance.getStatusColumnData(
            itemIds as number[],
            columnData.columnId
          );

          if (response) {
            setStatusColumnData(response.items[0].column_values[0]);
          }
        } catch (error) {
          console.error(error);
          throw error;
        }
      };
      fetchStatusColumnData(itemIds as number[]);
    }
  }, [itemIds, columnData.columnId, columnData.value]);

  if (!statusColumnData) {
    return null; // or a placeholder
  }

  return (
    <div
      className="px-3 py-1 font-semibold text-white rounded"
      style={{
        backgroundColor: statusColumnData?.label_style?.color || "",
      }}
    >
      {statusColumnData.text}
    </div>
  );
};

export default StatusColumn;

import React, { useEffect, useRef, useState } from "react";
import { ColumnValueRendererProps } from "../table.data.interface";
import { MondayApiQueryInstance } from "../../../services/monday/monday-service-ts";
import { getFileIcon } from "../../../utils/getFileIcon";
import { Tooltip } from "monday-ui-react-core";
import MondaySDK from "monday-sdk-js";
import useBoardAndItems from "../../../hooks/context/useBoardAndItems";

const monday = MondaySDK();

interface FileColumValueInterface {
  name: string;
  file_extension: string;
  url: string;
  url_thumbnail: string;
  id: number;
}

const FilesColumn = ({ columnData, itemIds }: ColumnValueRendererProps) => {
  const { currentBoard } = useBoardAndItems();
  const [fileColumnValue, setFileColumnValue] = useState<
    FileColumValueInterface[] | null
  >(null);
  const [showMore, setShowMore] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (itemIds && itemIds.length > 0) {
      const fetchStatusColumnData = async (itemIds: number[]) => {
        try {
          const response = await MondayApiQueryInstance.getFileColumnData(
            itemIds as number[],
            columnData.columnId
          );

          if (response) {
            setFileColumnValue(response.items[0].assets);
          }
        } catch (error) {
          console.error(error);
          throw error;
        }
      };
      fetchStatusColumnData(itemIds as number[]);
    }
  }, [itemIds, columnData.columnId]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowMore(false);
      }
    };

    if (showMore) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMore]);

  if (columnData.value === "Empty Column" || fileColumnValue === null) {
    return null;
  }

  const handleShowMore = () => setShowMore(!showMore);

  const handleFileOpenDialog = (assetId: number) => {
    try {
      monday.execute("openFilesDialog", {
        boardId: currentBoard as number,
        itemId: itemIds![0] as number,
        columnId: columnData.columnId,
        assetId: assetId,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const displayedItems = fileColumnValue.slice(0, 3);
  const remainingFiles = fileColumnValue.slice(3);

  return (
    <div className="relative flex items-center gap-2">
      {fileColumnValue &&
        displayedItems.map((file) => (
          <div
            key={file.id}
            className="flex items-center cursor-pointer"
            onClick={() => {
              handleFileOpenDialog(file.id);
            }}
          >
            <div className="flex items-center gap-1 text-sm no-underline text-[#323338]">
              <div className="flex items-center overflow-hidden rounded">
                {file.url_thumbnail ? (
                  <img
                    alt={file.name}
                    src={file.url_thumbnail}
                    width={24}
                    height={24}
                    className="rounded cursor-pointer"
                  />
                ) : (
                  getFileIcon(file.file_extension)
                )}
              </div>
              <Tooltip content={file.name}>
                <span className="overflow-hidden whitespace-nowrap text-ellipsis max-w-[10ch]">
                  {file.name}
                </span>
              </Tooltip>
            </div>
          </div>
        ))}

      {fileColumnValue.length > 3 && (
        <div
          onClick={handleShowMore}
          className="-mx-1 px-2 rounded-full text-white bg-[#DCDFEC] flex items-center justify-center cursor-pointer"
        >
          <span className="!font-poppins text-xs text-[#676879]">
            +{remainingFiles.length}
          </span>
        </div>
      )}

      {showMore && (
        <div
          ref={dropdownRef}
          className="absolute z-10 flex flex-col flex-wrap gap-2 p-2 bg-white bottom-[30px] shadow-custom min-w-[300px] rounded"
        >
          {remainingFiles.map((file) => (
            <div
              key={file.id}
              className="flex items-center gap-1 text-sm no-underline text-[#323338] w-full"
              onClick={() => {
                handleFileOpenDialog(file.id);
              }}
            >
              <div className="flex items-center cursor-pointer gap-1 p-2 rounded hover:bg-[#f5f6f8] transition-all w-full">
                <div className="flex items-center overflow-hidden rounded">
                  {file.url_thumbnail ? (
                    <img
                      alt={file.name}
                      src={file.url_thumbnail}
                      width={24}
                      height={24}
                      className="rounded cursor-pointer"
                    />
                  ) : (
                    getFileIcon(file.file_extension)
                  )}
                </div>
                <span className="overflow-hidden whitespace-nowrap text-ellipsis">
                  {file.name}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilesColumn;

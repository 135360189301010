import React from "react";
import { ColumnValueRendererProps } from "./table.data.interface";
import StatusColumn from "./columns/StatusColumn";
import useBoardAndItems from "../../hooks/context/useBoardAndItems";
import DateColumn from "./columns/DateColumn";
import PersonColumn from "./columns/PersonColumn";
import NumbersColumn from "./columns/NumbersColumn";
import EmailColumn from "./columns/EmailColumn";
import PhoneColumn from "./columns/PhoneColumn";
import TextColumn from "./columns/TextColumn";
import RatingColumn from "./columns/RatingColumn";
import LinkColumn from "./columns/LinkColumn";
import DropdownColumn from "./columns/DropdownColumn";
import LongTextColumn from "./columns/LongTextColumn";
import TimelineColumn from "./columns/TimelineColumn";
import CheckboxColumn from "./columns/CheckboxColumn";
import FilesColumn from "./columns/FilesColumn";
import ConnectBoardsColumn from "./columns/ConnectBoardsColumn";
import LocationColumn from "./columns/LocationColumn";
import TagsColumn from "./columns/TagsColumn";

const columnComponents: Record<
  string,
  React.ComponentType<ColumnValueRendererProps>
> = {
  status: StatusColumn,
  date: DateColumn,
  person: PersonColumn,
  people: PersonColumn,
  numbers: NumbersColumn,
  email: EmailColumn,
  phone: PhoneColumn,
  text: TextColumn,
  rating: RatingColumn,
  link: LinkColumn,
  dropdown: DropdownColumn,
  "long_text": LongTextColumn,
  timeline: TimelineColumn,
  checkbox: CheckboxColumn,
  file: FilesColumn,
  board_relation: ConnectBoardsColumn,
  location: LocationColumn,
  tags: TagsColumn,
};

const ColumnValueRenderer = ({ columnData }: ColumnValueRendererProps) => {
  const { itemIds } = useBoardAndItems();

  const ColumnComponent = columnComponents[columnData.type];

  if (!ColumnComponent) {
    // Fallback if column type is unsupported
    return <div>Unsupported Column Type</div>;
  }

  return <ColumnComponent columnData={columnData} itemIds={itemIds} />;
};

export default ColumnValueRenderer;

/* eslint-disable no-lone-blocks */
import React from "react";
import "monday-ui-react-core/dist/main.css";

import UsageSection from "./Usage/UsageSection";
import ItemViewSection from "./ItemViewSection/ItemViewSection";

const HomeSection = () => {
  return (
    <div className="px-[0.938rem] py-5">
      {/* Usage */}
      <div className="mb-[2.25rem]">
        <UsageSection />
      </div>

      {/* Main Item View Section */}
      <div>
        <ItemViewSection />
      </div>
      {/* <ItemsOnBoard /> */}
    </div>
  );
};

export default HomeSection;

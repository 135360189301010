import React from "react";
import { ColumnValueRendererProps } from "../table.data.interface";
import { Tooltip } from "monday-ui-react-core";

const PersonColumn = ({ columnData }: ColumnValueRendererProps) => {
  if (columnData.value === "Empty Column") {
    return null;
  }
  const arrayOfPersonsAndTeams = (columnData?.value as string).split(", ");
  const displayedItems = arrayOfPersonsAndTeams.slice(0, 3);
  const remainingPersonandTeams = arrayOfPersonsAndTeams.slice(3);

  return (
    <div className="flex font-semibold">
      {displayedItems.map((personAndTeam, index) => (
        <Tooltip content={personAndTeam} key={index}>
          <div className="-mx-1 w-[24px] h-[24px] rounded-full text-white bg-primary-color flex items-center justify-center border-solid border bg border-[#f5f6f8] cursor-pointer">
            <span className="!font-poppins text-xs">
              {personAndTeam.charAt(0).toUpperCase()}
            </span>
          </div>
        </Tooltip>
      ))}
      {arrayOfPersonsAndTeams.length > 3 && (
        <Tooltip
          content={
            <div className="flex flex-col">
              {remainingPersonandTeams.map((personAndTeam, index) => (
                <div key={index} className="text-xs text-white">
                  {personAndTeam}
                </div>
              ))}
            </div>
          }
        >
          <div className="-mx-1 px-2 rounded-full text-white bg-[#DCDFEC] flex items-center justify-center border-solid border bg border-[#f5f6f8] cursor-pointer">
            <span className="!font-poppins text-xs text-[#676879]">
              +{remainingPersonandTeams.length}
            </span>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default PersonColumn;

import React from "react";
import { ColumnValueRendererProps } from "../table.data.interface";
import { Check } from "monday-ui-react-core/icons";

const CheckboxColumn = ({ columnData }: ColumnValueRendererProps) => {
  if (columnData.value === "Empty Column") {
    return null;
  }
  return (
    <div>{columnData.value ? <Check size={20} color="#00c875" /> : ""}</div>
  );
};

export default CheckboxColumn;

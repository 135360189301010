import { useEffect, useState } from "react";
import { Settings } from "monday-ui-react-core/icons";
import Drawer from "@mui/material/Drawer";
import SettingsDrawerList from "./SettingsDrawerList";
import useGetSettings from "../../../hooks/api/Settings/useGetSettings";
import { IconButton } from "monday-ui-react-core";
import { SettingsData } from "./types/settings-drawer-interface";
import useSubitemSettings from "../../../hooks/api/useSubitemSettings";

const SettingsDrawer = () => {
  const [open, setOpen] = useState(false);
  const { settingsData, mutate, isLoading } = useGetSettings();

  const {
    itemSettingsLoading,
    error,
    subitemSettings,
    updateSettings,
    userId,
    itemIds,
  } = useSubitemSettings();

  const [switchValues, setSwitchValues] = useState<SettingsData | null>(
    settingsData
  );

  useEffect(() => {
    if (settingsData) {
      setSwitchValues(settingsData);
    }
  }, [settingsData]);

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  // Group useSubitemSettings values into an object
  const itemSettings = {
    itemSettingsLoading,
    error,
    subitemSettings,
    updateSettings,
    userId,
    itemIds,
  };

  return (
    <div>
      <IconButton
        onClick={() => toggleDrawer(true)}
        icon={Settings}
        size="medium"
        tooltipContent="Settings"
        kind={IconButton.kinds.SECONDARY}
      />

      <Drawer open={open} anchor="right" onClose={() => toggleDrawer(false)}>
        <SettingsDrawerList
          switchValues={switchValues}
          setSwitchValues={setSwitchValues}
          handleCloseDrawer={toggleDrawer}
          mutate={mutate}
          isLoading={isLoading}
          itemSettings={itemSettings}
        />
      </Drawer>
    </div>
  );
};

export default SettingsDrawer;

import MondaySdk from "monday-sdk-js";
import { MondayClientSdk } from "monday-sdk-js/types/client-sdk.interface";
import { handleMondayError } from "./monday-handle-error";
import { ChangeNamesVariables } from "../visual-indicator-service/visual-indicator-interface";
import {
  getItemNamesQl,
  getWorkspacesQl,
  getAccountSizeQl,
  checkSubitemsQl,
  checkUpdatesQl,
  getStatusColumnDataQl,
  getPersonColumnDataQl,
  getFileColumnDataQl,
} from "./monday-queries";

/**
 * @class MondayService
 */
export class MondayService {
  /**
   * @param userToken
   * @private
   */
  public async getMondayServerSdkForUser(): Promise<MondayClientSdk> {
    try {
      // @ts-ignore
      const monday: MondayClientSdk = new MondaySdk();
      monday.setApiVersion("2024-07");

      return monday;
    } catch (error) {
      throw error;
    }
  }

  public async batchMutateItemNames(
    query: string,
    variables: ChangeNamesVariables
  ) {
    try {
      console.log("batch mutate");

      const sdkForUser: MondayClientSdk =
        await this.getMondayServerSdkForUser();

      const response = await sdkForUser.api(query, { variables });

      console.log("Update item name response:", response);

      handleMondayError(response);
      return response;
    } catch (error) {
      console.error("batch mutate item name error:", error);
      throw error;
    }
  }

  public async getItemNames(itemIds: number[]) {
    try {
      const sdkForUser: MondayClientSdk =
        await this.getMondayServerSdkForUser();

      const response = await sdkForUser.api(getItemNamesQl(itemIds));

      handleMondayError(response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getWorkspaces() {
    try {
      const sdkForUser: MondayClientSdk =
        await this.getMondayServerSdkForUser();
      const response: any = await sdkForUser.api(getWorkspacesQl());

      handleMondayError(response);

      // Check if mainWorkspace exists and has the necessary properties
      const mainWorkspace = response?.data?.main_workspace?.[0]?.workspace;

      let mainWorkspaceEntry = null;
      if (mainWorkspace) {
        mainWorkspaceEntry = {
          id: mainWorkspace.id,
          name: mainWorkspace.name,
        };
      }

      // Combine the arrays, including mainWorkspaceEntry only if it's not null
      const combinedWorkspaces = mainWorkspaceEntry
        ? [...response.data.all_workspaces, mainWorkspaceEntry]
        : [...response.data.all_workspaces];

      const sortedWorkspaces = combinedWorkspaces.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      return sortedWorkspaces;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getAccountSize() {
    try {
      const sdkForUser: MondayClientSdk =
        await this.getMondayServerSdkForUser();
      const response: any = await sdkForUser.api(getAccountSizeQl());

      handleMondayError(response);

      return response.data.boards;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async checkSubitems(itemIds: Number[]) {
    try {
      const sdkForUser: MondayClientSdk =
        await this.getMondayServerSdkForUser();

      const response: any = await sdkForUser.api(checkSubitemsQl(itemIds));

      handleMondayError(response);

      return response?.data?.items;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async checkUpdates(itemIds: Number[]) {
    try {
      const sdkForUser: MondayClientSdk =
        await this.getMondayServerSdkForUser();

      const response: any = await sdkForUser.api(checkUpdatesQl(itemIds));

      handleMondayError(response);

      return response?.data?.items;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getStatusColumnData(itemIds: number[], columnId: string) {
    try {
      const sdkForUser: MondayClientSdk =
        await this.getMondayServerSdkForUser();

      const response: any = await sdkForUser.api(
        getStatusColumnDataQl(itemIds, columnId)
      );

      handleMondayError(response);

      return response?.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getPersonColumnData(itemIds: number[], columnId: string) {
    try {
      const sdkForUser: MondayClientSdk =
        await this.getMondayServerSdkForUser();

      const response: any = await sdkForUser.api(
        getPersonColumnDataQl(itemIds, columnId)
      );

      handleMondayError(response);

      return response?.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getFileColumnData(itemIds: number[], columnId: string) {
    try {
      const sdkForUser: MondayClientSdk =
        await this.getMondayServerSdkForUser();

      const response: any = await sdkForUser.api(
        getFileColumnDataQl(itemIds, columnId)
      );

      handleMondayError(response);

      return response?.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export const MondayApiQueryInstance: MondayService = new MondayService();

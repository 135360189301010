import useSWR from "swr";
import useUser from "../context/userHook";
import useBoardAndItems from "../context/useBoardAndItems";
import {
  getSharedSubitemsSettings,
  updateSharedSubitemsSettings,
} from "../../utils/requests/sharedSubitemsRequests";
import useTokenHook from "../context/useTokenHook";

export const useSubitemSettings = () => {
  const { userId } = useUser();
  const { itemIds } = useBoardAndItems();
  const { token } = useTokenHook();

  const { isLoading, error, data, mutate } = useSWR(
    token && userId ? `subitemSettings:${userId}` : null,
    () => getSharedSubitemsSettings(token, itemIds)
  );

  const updateSettings = async (itemIds: number[], checked: boolean) => {
    try {
      await updateSharedSubitemsSettings(token, itemIds, checked);
      mutate();
    } catch (error) {
      console.error(error);
    }
  };

  return {
    userId,
    itemIds,
    itemSettingsLoading: isLoading,
    error,
    subitemSettings: data ?? false,
    updateSettings,
  };
};

export default useSubitemSettings;

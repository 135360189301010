import React from "react";
import { useState, useEffect } from "react";
import mondaySdk from "monday-sdk-js";
import "monday-ui-react-core/dist/main.css";
import HomeSection from "../components/home";
import ErrorPage from "../components/errorPage";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UpdateVersion from "./update/new_version";
import useUser from "../hooks/context/userHook";
import { refreshToken } from "../redux/slices/accessTokenSlice";

const uri = process.env.REACT_APP_URI;
const monday = mondaySdk();

const Home = () => {
  const dispatch = useDispatch();
  const { userId } = useUser();
  const [error, setError] = useState(false);
  const [fetchAuth, setFetchAuth] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [version, setVersion] = useState(false);

  const navigate = useNavigate();
  monday.execute("valueCreatedForUser");

  useEffect(() => {
    if (fetchAuth) {
      function actionToExecute() {
        //replace the url
        axios
          .post(`${uri}/simb/check-userid-onboarding`, { userid: userId })
          .then((res) => {
            if (!res.data.error) {
              if (res.data.data) {
                clearInterval(intervalId);
              }
            }
          })
          .catch((error) => {
            console.log("error => ", error);
          });
      }
      const intervalId = setInterval(actionToExecute, 1000);
      return () => clearInterval(intervalId);
    }
  }, [userId, fetchAuth]);

  useEffect(() => {
    monday.execute("valueCreatedForUser");
    monday
      .get("context")
      .then((res) => {
        let dataVersion = res.data.appVersion.versionData.major;
        //version for force update

        //make the app true
        parseInt(dataVersion) < 27 ? setVersion(false) : setVersion(false);
        res.data.user.isViewOnly ? setIsViewOnly(true) : setIsViewOnly(false);
        let itemidsArray = [];
        res.data.appFeature.type === "AppFeatureItemBatchAction"
          ? (itemidsArray = res.data.selectedPulsesIds)
          : itemidsArray.push(res.data.itemId);

        //handle the array here
      })
      .catch((error) => {
        console.error(error.message);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    if (userId) {
      setFetchAuth(false);
      //replace the url
      axios
        .post(`${uri}/simb/check-userid-onboarding`, { userid: userId })
        .then(async (res) => {
          if (!res.data.error) {
            if (res.data.data === true) {
              dispatch(refreshToken(userId));
            } else if (res.data.data === false) {
              navigate("/onboarding");
            }
          } else {
            setError(true);
          }
        })
        .catch((error) => {
          console.log("error => ", error);
          setError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <div>
      {isViewOnly ? (
        <div className="flex items-center justify-center h-screen">
          <div className="px-4 py-3 bg-red-100 rounded">
            <span className="block sm:inline">
              As a viewer, you are unable to use the SIMB.
            </span>
          </div>
        </div>
      ) : !error ? (
        version ? (
          <UpdateVersion />
        ) : (
          <HomeSection />
        )
      ) : (
        <ErrorPage />
      )}
    </div>
  );
};

export default Home;

import {
  FaFileWord,
  FaFileExcel,
  FaFilePdf,
  FaFileCsv,
  FaFileVideo,
  FaFileImage,
} from "react-icons/fa";
import { BiSolidFileGif } from "react-icons/bi";
import { SiAdobeillustrator } from "react-icons/si";
import { FiFileText } from "react-icons/fi";

export const getFileIcon = (extension: string) => {
  switch (extension.toLowerCase()) {
    case ".doc":
    case ".docx":
      return <FaFileWord size={24} color="#1E88E5" />;
    case ".pdf":
      return <FaFilePdf size={24} color="#FF5252" />;
    case ".xlsx":
    case ".xls":
      return <FaFileExcel size={24} color="#4CAF50" />;
    case ".csv":
      return <FaFileCsv size={24} color="#FF9800" />;
    case ".mp4":
    case ".mov":
    case ".avi":
      return <FaFileVideo size={24} color="#FF5722" />;
    case ".jpeg":
    case ".jpg":
    case ".png":
    case ".bmp":
    case ".tiff":
    case ".webp":
    case ".svg":
      return <FaFileImage size={24} color="#03A9F4" />;
    case ".gif":
      return <BiSolidFileGif size={24} color="#FF4081" />;
    case ".txt":
      return <FiFileText size={24} color="#8E8E8E" />;
    case ".ai":
      return <SiAdobeillustrator size={24} color="#FFAB40" />;
    default:
      return <FaFileImage size={24} color="#03A9F4" />;
  }
};

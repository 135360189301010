import simbApi from ".";
import { USAGE_DATA } from "../../data/constants/endpoints";

export const fetchUsage = async (access_token: string) => {
  try {
    const response = await simbApi.get(USAGE_DATA, {
      headers: { Authorization: `Bearer ${access_token}` },
    });

    if (response?.data?.error) {
      throw new Error(response?.data?.error);
    }

    return response?.data.usageData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

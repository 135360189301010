import React from "react";
import { ColumnValueRendererProps } from "../table.data.interface";

const TextColumn = ({ columnData }: ColumnValueRendererProps) => {
  if (columnData.value === "Empty Column") {
    return null;
  }
  return <div>{columnData.value}</div>;
};

export default TextColumn;
